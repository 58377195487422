import React from "react";
import styles from "./Brands.module.css";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Brands = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 1000,
    centerMode: true,
  };
  return (
    <>
      <div className={styles.Section}>
        <div className={styles.sliderContainer}>
          <Slider {...settings}>
            <div>
              <img src="assets/Brand-logos/1.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/2.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/3.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/4.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/5.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/6.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/7.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/8.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/9.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/10.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/11.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/12.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/13.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/14.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/15.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/16.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/17.jpg" alt="" />
            </div>
            <div>
              <img src="assets/Brand-logos/18.jpg" alt="" />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Brands;
