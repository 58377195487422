import styles from "./ManufacturePage.module.css";
import ContactBtn from "../Buttons/Button";
import Footer from "../Footer/Footer";
import Brands from "../Brands/Brands";
import backgroundVideo from "./video/manufecture.mp4";

const ManufacturePage = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        {/* <div className={styles.containerBanner}> */}

        {/* <video autoplay muted loop id={styles.video}>
         <source src={backgroundVideo} type="video/mp4" />
        </video> */}
        {/* <div className={styles.gradientbg}></div> */}
        {/* </div> */}

        <div className={styles.manufacturingPage}>
          <div className={styles.MenufactureIntro}>
            <div className={styles.FlatKnitsHeading}>
              <h1>Manufacturing</h1>
              <h2>
                World-Class Manufacturing at <br /> Active Clothing
              </h2>
              <p>
                Our manufacturing facility at Active Clothing
                covers a vast area of<span> 7.5 acres</span>, with a built-up
                area of approximately <span> 4,00,000 square feet</span>. This
                expansive space houses some of the best technologies sourced
                from
                <span> Germany</span> and <span>Japan</span>. Our workforce
                comprises over <span>2000+ skilled individuals</span>, including
                a diverse team managing various aspects of talent and
                production.
              </p>
              {/* <a href="#imagesSection">
                <ContactBtn label="Explore Our Collections" />
              </a> */}
            </div>
          </div>
        </div>

        {/* Sections Start */}
        <div className={styles.sectionsContainer}>
          <div className={styles.sectionImages} id={styles.sectionImages1}>
            {/* <img src="assets/clothes-images/Flat-Knits.jpg" alt="" /> */}

            <a href="/flatKnits">
              {" "}
              <h2>Flat Knits</h2>
            </a>
            <p>
            Expertly crafted, premium quality knits offering superior comfort and style for everyday wear.
            </p>
          </div>
          <div className={styles.sectionImages} id={styles.sectionText}>
            <h2>Crafting excellence, stitching success</h2>
            <p>Active Clothing, where innovation meets quality.</p>
          </div>
          <div className={styles.sectionImages} id={styles.sectionImages2}>
            {/* <img src="assets/clothes-images/Flat-Knits.jpg" alt="" /> */}
            <a href="/circularKnits">
              <h2>Circular Knits</h2>
            </a>
            <p>
            Versatile, high-performance fabrics known for their durability, comfort, and flexibility in casual and activewear.
            </p>
          </div>
        </div>

        <div className={styles.sectionsContainer}>
          <div className={styles.sectionImages} id={styles.sectionText}>
            <h2>Unleashing the power of fabric</h2>
            <p>we are your partner in fashion evolution.</p>
          </div>
          <div className={styles.sectionImages} id={styles.sectionImages3}>
            {/* <img src="assets/clothes-images/Flat-Knits.jpg" alt="" /> */}
            <a href="/Jackets">
              <h2>Jackets</h2>
            </a>
            <p>
            Stylish, durable, and expertly crafted jackets designed for comfort, functionality, and all-weather protection.
            </p>
          </div>
          <div className={styles.sectionImages} id={styles.sectionText}>
            <h2>From concept to creation,</h2>
            <p>we delivers perfection in every stitch.</p>
          </div>
        </div>

        <div className={styles.sectionsContainer}>
          <div className={styles.sectionImages} id={styles.sectionImages4}>
            {/* <img src="assets/clothes-images/Flat-Knits.jpg" alt="" /> */}
            <a href="/shoeUppers">
              <h2>Shoes Uppers</h2>
            </a>
            <p>
            Precision-crafted, durable, and stylish uppers designed for superior fit and long-lasting comfort.
            </p>
          </div>
          <div className={styles.sectionImages} id={styles.sectionText}>
            <h2>Driven by craftsmanship, powered by creativity</h2>
            <p>setting new standards in apparel.</p>
          </div>
          <div className={styles.sectionImages} id={styles.sectionImages5}>
            {/* <img src="assets/clothes-images/Flat-Knits.jpg" alt="" /> */}
            <a href="/toys">
              <h2>Toys</h2>
            </a>
            <p>
            Fun, safe, and thoughtfully designed toys crafted with quality materials for children's enjoyment and development.
            </p>
          </div>
        </div>

        {/* Sections End */}
      </div>
      <Brands />

      <Footer />
    </>
  );
};

export default ManufacturePage;
