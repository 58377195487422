import "./App.css";
import Header from "./components/Header/Header";
import ManufacturePage from "./components/Manufacturing/ManufacturePage";
import HomePage from "./components/HomePage/HomePage" ;
import { Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import Retailing from "./components/Retailing/Retail";
import Distribution from "./components/Distribution/Distribution";
import FlatKnits from "./components/Manufacturing/Flat Knits/FlatKnits";
import CircularKnits from "./components/Manufacturing/Circular Knits/CircularKnits";
import Jackets from "./components/Manufacturing/Jackets/Jackets";
import ShoeUppers from "./components/Manufacturing/ShoesUppers/ShoeUppers";
import Toys from "./components/Manufacturing/Toys/Toys";
import AboutUs from "./components/AboutUs/About";
import DesignDelopment from "./components/Design&Development/DesignDelopment";
import ContactUs from "./components/ConatctUs/ContactUs";
import OurPeople from "./components/OurPeople/OurPeople";
import OurValues from "./components/Values/Values";
import NetworkCapacity from "./components/NetworkCapacity/NetworkCapacity";
import OurBusiness from "./components/Business/Business";
import Awards from "./components/Awards/Awards";
import Certifications from "./components/Certifications/Certifications";
import EventNews from "./components/Event-news/EventNews";
import Investors from "./components/Investors/Investors";
import Profile from "./components/Profile/Profile";
import Financials from "./components/Financials/Financials";
import OurHistory from "./components/History/OurHistory";
import Kiaandco from "./components/KiaAndCo/Kiaandco";

function App() {
  return (
    <>
      <Header />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/manufacturing" element={<ManufacturePage />} />

        <Route path="/flatKnits" element={<FlatKnits />} />
        <Route path="/circularKnits" element={<CircularKnits />} />
        <Route path="/jackets" element={<Jackets />} />
        <Route path="/ShoeUppers" element={<ShoeUppers />} />
        <Route path="/toys" element={<Toys />} />

        <Route path="/about" element={<AboutUs />} />
        <Route path="/retailing" element={<Retailing />} />
        <Route path="/distribution" element={<Distribution />} />
        <Route path="/kiaandco" element={<Kiaandco />} />
        <Route path="/sidebar" element={<Sidebar />} />
        <Route path="/designDevelopment" element={<DesignDelopment />} />
        <Route path="/contactUs" element={<ContactUs />} />

        <Route path="/our-people" element={<OurPeople />} />
        <Route path="/our-values" element={<OurValues />} />
        <Route path="/networkCapacity" element={<NetworkCapacity />} />
        <Route path="/ourbusiness" element={<OurBusiness />} />
        <Route path="/certifications" element={<Certifications />} />
        <Route path="/awards" element={<Awards />} />
        <Route path="/history" element={<OurHistory />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/eventNews" element={<EventNews />} />
        <Route path="/financials" element={<Financials />} />
        {/* <Route path='/eventNews' element={<EventNews />}/>  */}
      </Routes>
    </>
  );
}

export default App;
