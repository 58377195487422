import React from "react";
import styles from "./Values.module.css";
import Footer from "../Footer/Footer";
import ContactBtn from "../Buttons/Button";
import Brands from "../Brands/Brands";

const OurValues = () => {
  return (
    <>
     <div className={styles.OurValuesPage}>
        <div className={styles.OurValuesIntro}>
          <div className={styles.OurValuesHeading}>
            <h1>Our Values</h1>
            <h2>The Foundation of Active Clothing
            </h2>
            <p>
              At Active Clothing, our values are the guiding principles that
              shape every decision we make. Integrity, innovation, and
              sustainability are at the core of our business, driving us to
              deliver exceptional quality while respecting the environment and
              empowering communities. We are committed to fostering long-term
              relationships with our customers, partners, and employees, built
              on trust, transparency, and mutual respect. Our values inspire us
              to push boundaries, create responsibly, and lead with purpose in
              the textile industry.
            </p>
            {/* <a href="#imagesSection">
              <ContactBtn label="Our Values" />
            </a> */}
          </div>
        </div>
      </div>

      {/* Gallery Start */}
    <div className={styles.valuesContainer}>
      <div className={styles.imageSection}>
          <h3>Leadership</h3>
          <img src="assets/leadership.jpg" alt="" />
          <p>
            We lead by example, setting high standards and inspiring positive
            change. Our leaders mentor and develop future leaders, fostering a
            culture of strong, visionary leadership.
          </p>
        </div>
        <div className={styles.imageSection} id={styles.IdSection}>
          <h3>Learning</h3>
          <img src="assets/learning.jpg" alt="" />
          <p>
            We embrace continuous learning, offering growth through training and
            industry resources. Curiosity and lifelong learning drive our
            personal and professional development.
          </p>
        </div>
        <div className={styles.imageSection}>
          <h3>Integrity</h3>
          <img src="assets/trust.jpg" alt="" />
          <p>
            Integrity is our foundation. We adhere to the highest ethical
            standards, ensuring honesty, transparency, and accountability. We
            build trust by consistently delivering on our promises.
          </p>
        </div>
        </div>

        <div className={styles.valuesContainer} >
        <div className={styles.imageSection}>
          <h3>Innovation</h3>
          <img src="assets/Jackets/92.jpg" alt="" />
          <p>
            Innovation drives our success. We explore new ideas and
            technologies, fostering creativity and experimentation to develop
            cutting-edge solutions that meet the evolving needs of our clients.
          </p>
        </div>

        <div className={styles.imageSection}>
          <h3>People</h3>
          <img src="assets/people-support.jpg" alt=""  />
          <p>
            Our people are our greatest asset. We create a supportive and
            inclusive environment, celebrating diversity and prioritizing the
            well-being and development of our team members.
          </p>
        </div>
        <div className={styles.imageSection}>
          <h3>Performance</h3>
          <img src="assets/win.jpg" alt="" />
          <p>
             We strive for excellence, setting ambitious goals and
            delivering high-quality results. Through continuous improvement, we
            ensure our clients receive the best possible outcomes, exceeding
            expectations.
          </p>
        </div>
    </div>

      {/* Gallery End */}
      <Brands />

      <Footer />
    </>
  );
};

export default OurValues;
