import React from 'react'
import ContactBtn from '../Buttons/Button';
import Brands from '../Brands/Brands';
import Footer from '../Footer/Footer';
import styles from "./Investors.module.css";

const Investors = () => {
  return (
   <>
    <div className={styles.manufacturingPage}>
        <div className={styles.MenufactureIntro}>
          <div className={styles.FlatKnitsHeading}>
            <h1>
            Investors
            </h1>
            <p>
              Active Clothing has built a strong reputation in the apparel
              distribution industry, starting with the distribution of
              <span className={styles.span}> Levi’s</span> and growing to become the{" "}
              <span className={styles.span}> 
                largest Levi’s distributor in the upper north of India
              </span>
              . Operating in regions like Chandigarh, Punjab, Jammu & Kashmir,
              and Himachal Pradesh, the company now distributes top brands such
              as Arrow, Flying Machine, Rare Rabbit, and more.{" "}
              <span className={styles.span}>Serving over 200 retailers in 50 towns </span>, Active
              Clothing continues to expand its reach and has received multiple
              awards for its achievements in the industry.
            </p>
          </div>
        </div>
      </div>

    <Brands />
      <Footer />
   </>
  );
};

export default Investors