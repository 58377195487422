import React from "react";
import styles from "./ContactUs.module.css";
import ContactBtn from "../Buttons/Button";
import Footer from "../Footer/Footer";
import Brands from "../Brands/Brands";

const ContactUs = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.containerBanner}>
          <img src="assets/trust.jpg" alt="" />
          <div className={styles.gradientbg}></div>
        </div>

        <div className={styles.manufacturingPage}>
          <div className={styles.MenufactureIntro}>
            <div className={styles.FlatKnitsHeading}>
              <h1>Get in Touch with Us</h1>
              <p>
                We’re here to help you with any inquiries or collaboration
                opportunities. Whether you’re looking for more information about
                our services or ready to turn your designs into reality, our
                team is just a message away. Reach out today, and let’s explore
                how we can bring your vision to life.
              </p>
              <a href="#contact-us">
                <ContactBtn label="Contact Us" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.CorporateOffice}>
        <div className={styles.FormSection}>
          <h2>Corporate Office</h2>

          <h4>Address:</h4>
          <p>
            Plot no. E-225, Phase 8B, Ind. Area, Mohali, Punjab, India-160059
          </p>
          <h4>Phone:</h4>
          <p>0172-4313300</p>
          <h4>Investor Grievances:</h4>
          <p>csco@activesourcing.org</p>
          <h4>Web:</h4>
          <p>www.activeclothing.in</p>
        </div>

        <div className={styles.FormSection}>
          <img src="assets/60.jpg" alt="" />
        </div>
      </div>

      <div className={styles.CorporateOffice}>
        <div className={styles.FormSection}>
          <img src="assets/54.JPG" alt="" />
        </div>
        <div className={styles.FormSection}>
          <h2>Factory</h2>

          <h4>Address:</h4>
          <p>
            Plot no. E-225, Phase 8B, Ind. Area, Mohali, Punjab, India-160059
          </p>
          <h4>Phone:</h4>
          <p>0172-4313300</p>
          <h4>Investor Grievances:</h4>
          <p>csco@activesourcing.org</p>
          <h4>Web:</h4>
          <p>www.activeclothing.in</p>
        </div>
      </div>

      <div className={styles.formContainer} id="contact-us">
        <form action="">
          <h2>CONTACT US </h2>
          <input type="text" placeholder="Name" required />
          <br />
          <input type="text" placeholder="Address" />
          <br />
          <input type="email" placeholder="Email" required />
          <br />
          <input type="number" placeholder="Mobile No." required />
          <br />
          <input type="text" placeholder="Message" />
          <br />
          <div className={styles.formBtn}>
            <ContactBtn label="SUBMIT" type="SUBMIT" />
            <ContactBtn label="RESET" type="RESET" />
          </div>
        </form>
      </div>
      <Brands />
      <Footer />
    </>
  );
};

export default ContactUs;
