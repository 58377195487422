import React from "react";
import styles from "./Profile.module.css";
import ContactBtn from "../Buttons/Button";
import Footer from "../Footer/Footer";
import Brands from "../Brands/Brands";

const Profile = () => {
  return (
    <>
      <div className={styles.manufacturingPage}>
        <div className={styles.MenufactureIntro}>
          <div className={styles.FlatKnitsHeading}>
            <h1>
              Company Profile
            </h1>
           
            <a href="#imagesSection">
            <a
          target="_blank"
          href="https://activeclothing.in/wp-content/uploads/2024/09/company-profile.pdf"
        ><ContactBtn label="OPEN PDF" /> </a>
            </a>
          </div>
        </div>
      </div>
      <Brands />
      <Footer />
    </>
  );
};

export default Profile;
