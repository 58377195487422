import styles from "./Jackets.module.css";
import ContactBtn from "../../Buttons/Button";
import Footer from "../../Footer/Footer";
import Brands from "../../Brands/Brands";

const Jackets = () => {
  return (
    <>
    <div className={styles.mainContainer}>
        <div className={styles.containerBanner}>
          <img src="assets/BG/jecketsBG.jpeg" alt="" />
          <div className={styles.gradientbg}></div>
        </div>

        <div className={styles.manufacturingPage}>
          <div className={styles.MenufactureIntro}>
            <div className={styles.FlatKnitsHeading}>
          <h1>Jackets</h1>
            <h2>
          Punjab's Largest Jacket Manufacturer – <br />
          57 jackets per hour
        </h2>
        <p>
          At Active Clothing Co. Limited, we pride ourselves on a legacy of
          innovation in jacket manufacturing. With
          <span> 57 jackets per hour</span> using cutting-edge technology and a
          facility equipped with
          <span> over 350 machines across 8 lines</span>, we are Punjab's
          largest outerwear manufacturer. Our diverse collection ranges from
          polyester-based quilted jackets to washed down cotton jackets ,
          offering styles for every preference. Crafted from high-quality
          materials like cotton, polyester, printed fabrics, and wool, each
          jacket combines meticulous craftsmanship with contemporary design to
          ensure both comfort and style.
        </p>
              <a href="#imagesSection">
                <ContactBtn label="Explore Our Collections" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.JacketsContainer1}>
        <div className={styles.ImagesSection}>
          <h3> A Legacy of Style and Innovation</h3>
          <p>
            Active Clothing Co. Limited has a long-standing tradition of
            innovation in jacket manufacturing. Our collection features a range
            of jackets, from polyester-based quilted jackets to washed down
            cotton jackets, each meticulously designed with a keen eye for
            detail and contemporary style.
          </p>
        </div>
        <div className={styles.JacketsImages}>
          <h3>Diverse Fabrics, Distinctive Designs</h3>
          <p>
            Our jackets are made from a variety of fabrics, including <span className={styles.span}>cotton,
            polyester, printed fabrics, and wool</span>, offering our customers a wide
            range of choices. Whether you prefer a classic look or a more daring
            style, we have something to match your preference.
          </p>
        </div>
      {/* </div> */}
      {/* <div className={styles.JacketsContainer2}> */}
        <div className={styles.ImagesSection}>
          <h3>Quality and Quantity</h3>
          <p>
            With a production <span className={styles.span}>capacity of 500,000 pieces
            per year</span>, we are the largest manufacturer of outerwear jackets in
            Punjab. Our advanced facility houses <span className={styles.span}>over 350 machines across 8
            lines</span>, ensuring that each jacket is crafted with the same level of
            care and attention to detail.
          </p>
        </div>
      </div>








      

    
    {/* Gallery Start */}
    <div className={styles.container} id="imagesSection">
            <div className={styles.imageSection}>
                <img src="https://activeclothing.in/wp-content/uploads/2024/07/1-2.jpg" alt="" />
            </div>
            <div className={styles.imageSection}>
                <img src="https://activeclothing.in/wp-content/uploads/2024/07/2-2.jpg" alt="" />
            </div>
        </div>
      {/* Gallery End */}
      <Brands />
      <Footer />
    </>
  );
};

export default Jackets;
