import styles from "./About.module.css";
import ContactBtn from "../Buttons/Button";
import Footer from "../Footer/Footer";
import Brands from "../Brands/Brands";

const AboutUs = () => {
  return (
    <>
    <div className="container-fluid">
        <div className={styles.aboutUsIntro}>
        <div className="row">
            <div className="col-12">
            <div className={styles.FlatKnitsHeading}>
              <h1>About Active Clothing</h1>
            <h2>
              Crafting Excellence for Global Apparel – A Legacy of Quality
              and Innovation
            </h2>
            <p>
              Active Clothing Co. Limited entered India’s apparel manufacturing
              landscape decades ago, and our journey of rapid growth continues
              today. With a team of over <span className={styles.span}>2,000 dedicated professionals</span>, we are an
              integrated apparel powerhouse, offering end-to-end solutions in
              garment design and production for global markets. We excel in
              creating a wide range of high-quality garments, spanning flat-knit
              sweaters, outerwear jackets, circular-knit t-shirts, sweatshirts,
              joggers, and shoe uppers. Our diverse collection serves men,
              women, kids, and baby wear, boasting an impressive hourly output
              of <span className={styles.span}> 277 pieces per hour</span> of knitwear,
              <span className={styles.span}> 57 jackets per hour</span>, 1 million t-shirts, and{" "}
              <span className={styles.span}>86 pairs per hour of shoe uppers</span>. Backed by
              cutting-edge technology, a strong infrastructure, and a passionate
              team, our commitment to excellence has made us a dominant player
              in the apparel industry. Despite global challenges, we’ve seen
              remarkable growth, achieving revenues of{" "}
              <span className={styles.span}>Rs. 212.34 crore in FY 2023-24</span>. From small production
              orders for Levi’s, we have expanded to serve renowned
              international brands, solidifying our presence on the global
              stage. At Active Clothing, our reputation for quality,
              reliability, and competitive pricing fuels our continued success.
            </p>
          </div>
            </div>
            {/* <div className="col-5">
            <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/n9WkQDb-Ih0?si=doX4HVpR5uMLJGWP"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
            </div> */}
        </div>
    </div>
    </div>


      <div className={styles.container}>
        <div className={styles.innerBoxes}>
          <h2>6,600</h2>
          <p>Knitwears Daily</p>
        </div>
        <div className={styles.innerBoxes}>
          <h2>1,400</h2>
          <p>Jackets Daily</p>
        </div>
        <div className={styles.innerBoxes}>
          <h2>2,800</h2>
          <p>T-Shirts Daily</p>
        </div>
        <div className={styles.innerBoxes}>
          <h2>2,100</h2>
          <p>Shoe Pairs Daily</p>
        </div>
      </div>

     

      <div className={styles.VissionContainer}>
        <div className={styles.Vissionsections}>
          <h2>Our Vision</h2>
          <p>
            We aim to be a global leader in fashion-knit and outerwear, driving
            innovation to exceed customer expectations. Our focus is on creating
            a supportive environment for partners and employees while upholding
            our responsibility as a conscientious corporate citizen.
          </p>
        </div>
        <div className={styles.Vissionsections}>
          <h2>Our Mission</h2>
          <p>
            <ul>
              <li>
                Innovation and Leadership: To pioneer, lead, and elevate by
                offering best-value products and services to our global
                clientele.
              </li>
              <li>
                Trendsetting and Adaptability: To stay ahead of fashion trends,
                market dynamics, and technological advancements through our
                robust branding.
              </li>
              <li>
                Quality of Life: To improve the quality of life for our business
                partners, customers, and employees.
              </li>
            </ul>
          </p>
        </div>
      </div>
      
      <div className={styles.activeBanner}>
        <img src="assets/56.jpg" alt="" />
      </div>

      {/* Awards Start */}
      <div className={styles.awardsContainer}>
        <div className={styles.sections}>
          <h2>AWARDED COMPANY OF THE YEAR</h2>
          <p>
            We’re proud to have been named
            <span className={styles.span}> “Company of the Year – Textiles” </span> for 2021 by TV9
            Network and SAP, a testament to our commitment to excellence. This
            success is thanks to our visionary Director,
            <span className={styles.span}> Mr. Rajesh Mehra</span>, and our dedicated team. As one of
            <span className={styles.span}> India’s top 3rd party manufacturers for apparel brands</span>
            , we’re committed to innovation and excellence. Join us on this
            journey!
          </p>
        </div>
        <div className={styles.sections}>
          <img src="assets/Award/award.jpg" alt="" />
        </div>
      </div>
      <Brands />
      {/* Awards End */}
      <Footer />
    </>
  );
};

export default AboutUs;
