import React from "react";
import styles from "./Toys.module.css";
import ContactBtn from "../../Buttons/Button";
import Footer from "../../Footer/Footer";
import Brands from "../../Brands/Brands";

const Toys = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.containerBanner}>
          <img src="assets/BG/toysBG.jpeg" alt="" />
          <div className={styles.gradientbg}></div>
        </div>

        <div className={styles.manufacturingPage}>
          <div className={styles.MenufactureIntro}>
            <div className={styles.toysHeading}>
              <h1>Toys</h1>
              <h2>
                Fun and Educational Toys for Kids at <br />
                Active Clothing
              </h2>
              <p>
                Clothing, we go beyond just clothing by offering a wide range of
                toys designed to both entertain and educate children. Our toys
                cater to various age groups and interests, focusing on{" "}
                <span>fun</span>, <span>creativity</span>, and
                <span> learning</span>. Each product is crafted with care,
                ensuring high quality and safety standards. Whether it’s an
                interactive plaything or an educational tool, our toys are made
                to support children's development and provide enjoyable
                experiences for families everywhere.
              </p>
              <a href="#imagesSection">
                <ContactBtn label="Explore Our Collections" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.toysContainer1}>
        <div className={styles.ImagesSection}>
          <h3>Educational & Engaging Toys for Global Families</h3>
          <p>
            Our commitment to innovation means that every toy we create is not
            only entertaining but also supports educational growth. From
            stimulating educational products to engaging play items, our toys
            are designed to provide enriching experiences and wholesome
            entertainment for families around the globe.
          </p>
        </div>
      </div>

      {/* Gallery Start */}
      <div className={styles.container} id="imagesSection">
        <div className={styles.imageSection}>
          <img src="assets/Toys/1.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/2.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/3.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/4.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/5.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/6.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/7.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/8.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/9.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/10.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/11.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/12.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/13.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/14.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/15.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/16.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/17.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/18.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/19.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/20.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/21.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/22.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/23.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/24.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/25.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/26.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/27.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/28.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/29.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/30.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/31.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/32.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/33.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/34.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/35.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/36.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/37.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/38.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/39.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/40.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/41.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/42.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/43.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/44.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/45.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/46.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/47.jpg" alt="" />
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Toys/48.jpg" alt="" />
        </div>
      </div>
      {/* Gallery End */}
      <Brands />
      <Footer />
    </>
  );
};

export default Toys;
