import React from "react";
import styles from "./FlatKnits.module.css";
import ContactBtn from "../../Buttons/Button";
import Footer from "../../Footer/Footer";
import Brands from "../../Brands/Brands";
const FlatKnits = () => {
  return (
    <>
    <div className={styles.mainContainer}>
        <div className={styles.containerBanner}>
          <img src="assets/BG/flatKnitsBG.jpeg" alt="" />
          <div className={styles.gradientbg}></div>
        </div>

        <div className={styles.manufacturingPage}>
          <div className={styles.MenufactureIntro}>
            <div className={styles.FlatKnitsHeading}>
              <h1>Flat Knits</h1>
            <h2>
          India's Third Largest sweater manufacturer - <br />
          Discover precision, quality and innovation with <br />
          Active Clothing.
        </h2>
        <p>
          Active Clothing Co. Limited leads the industry with <span>400
          state-of-the-art</span> , fully fashioned computerized machines from SHIMA
          SEIKI, STOLL, and KAUO HENG. With gauges ranging from 5gg to 18gg and
          multi-gauge capabilities, our annual <span>capacity of 2,400,000 units</span> sets
          us apart as the <span> third-largest manufacturer in India</span> and the largest in
          Punjab for sweaters.
        </p>
              <a href="#imagesSection">
                <ContactBtn label="Explore Our Collections" />
              </a>
            </div>
          </div>
        </div>
      </div>



      <div className={styles.FlatKnitsContainer1}>
        <div className={styles.ImagesSection}>
          <h3>Modern Facilities</h3>
          <p>
            Our facilities are equipped with the latest in laundry, garment
            printing, computerized embroidery, dyeing, quilting, and laser
            cutting. These advancements ensure that each garment meets the
            highest standards of quality and reflects the latest trends in
            fashion.
          </p>
        </div>
        <div className={styles.FlatKnitsImages}>
          <h3>Quality Yarns</h3>
          <p>
            We use only the finest yarns, including 100% cotton, acro-wool,
            cotton modal, merino wool, acrylic, viscose, indigo, lambswool, and
            various fancy yarns. This attention to detail guarantees that our
            garments are not just fashionable but also comfortable and
            long-lasting.
          </p>
        </div>
      </div>
      
      <div className={styles.FlatKnitsContainer2}>
        <div className={styles.ImagesSection}>
          <h3>Value Addition</h3>
          <p>
            We believe in adding value to every garment we produce. That’s why
            we’ve invested in fully computerized embroidery, quilting, and laser
            cutting machines. These machines allow us to add intricate details
            and embellishments, making each piece a true work of art.
          </p>
        </div>
        <div className={styles.FlatKnitsImages}>
          <h3>Cutting-Edge Equipment</h3>
          <p>
            Our factory is outfitted with top-of-the-line garment manufacturing
            and finishing equipment, including automatic Zuki-sewing machines.
            Our laundry and printing facilities are capable of delivering
            exceptional washes and treatments, ensuring that our garments stand
            out for their quality and craftsmanship.
          </p>
        </div>
      </div>

      {/* Gallery Start */}
      <div className={styles.container} id="imagesSection">
        <div className={styles.imageSection}>
          <img
            src="https://activeclothing.in/wp-content/uploads/2024/07/1-3.jpg"
            alt=""
          />
        </div>
        <div className={styles.imageSection}>
          <img
            src="https://activeclothing.in/wp-content/uploads/2024/07/2-3.jpg"
            alt=""
          />
        </div>
        <div className={styles.imageSection}>
          <img
            src="https://activeclothing.in/wp-content/uploads/2024/07/3-2.jpg"
            alt=""
          />
        </div>
        <div className={styles.imageSection}>
          <img
            src="https://activeclothing.in/wp-content/uploads/2024/07/4-2.jpg"
            alt=""
          />
        </div>
      </div>
      {/* Gallery End */}
      <Brands />
      <Footer />
    </>
  );
};

export default FlatKnits;
