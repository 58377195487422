import React from "react";
import styles from "./Distribution.module.css";
import ContactBtn from "../Buttons/Button";
import Footer from "../Footer/Footer";
import Brands from "../Brands/Brands";

const Distribution = () => {
  return (
    <>
      <div className={styles.manufacturingPage}>
        <div className={styles.MenufactureIntro}>
          <div className={styles.FlatKnitsHeading}>
            <h1>Distribution</h1>
            <h2>
              Leading Apparel Distributor in <br />
              North India
            </h2>
            <p>
              Active Clothing has built a strong reputation in the
              apparel distribution industry, starting with the distribution of
              <span> Levi’s</span> and growing to become the <span> largest Levi’s distributor in the
              upper north of India</span>. Operating in regions like Chandigarh,
              Punjab, Jammu & Kashmir, and Himachal Pradesh, the company now
              distributes top brands such as Arrow, Flying Machine, Rare Rabbit,
              and more. <span>Serving over 200 retailers in 50 towns </span>, Active Clothing
              continues to expand its reach and has received multiple awards for
              its achievements in the industry.
            </p>
            {/* <a href="#imagesSection">
              <ContactBtn label="Explore Our Collections" />
            </a> */}
          </div>
        </div>
      </div>

    {/* <div className={styles.tuckImage}>
      <img src="assets/social-icons/truck.png" alt="" />
    </div> */}

      <Brands />
      <Footer />
    </>
  );
};

export default Distribution;
