import React from "react";
import styles from "./HeroBanner.module.css";
import backgroundVideo from './activeVideos/active.mp4';

const HeroBanner = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        {/* <div className={styles.containerBanner}>
          <div className={styles.gradientbg}></div>
        </div> */}

        <video autoPlay loop muted id={styles.video}>
          <source src={backgroundVideo} type="video/mp4" />
        </video>

        {/* <div className={styles.bannerHeadings}>
          <h1 className={styles.heroHeading}>
            Innovating Style from Production to Retail
          </h1>
          <h3 className={styles.heroSubHeading}>
            Expertise in Jackets, Circular Knits, and Shoe Uppers
          </h3>
          <button className={styles.ctaBtn}>Explore Our Products</button>
        </div> */}

        {/* Bottom sections */}
        {/* <div className={styles.bannerBottomContainer}>
                <div className={styles.bannerBottomsections}>
                <a href="/manufacturePage.jsx"><img src="assets/social-icons/scissors.png" alt="" />
                    <h2>manufacturing</h2></a>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                <div className={styles.bannerBottomsections}>
                   <a href="#"><img src="assets/social-icons/scissors.png" alt="" />
                    <h2>Retail</h2></a>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p>
                </div>
                <div className={styles.bannerBottomsections}>
                <a href="#"><img src="assets/social-icons/scissors.png" alt="" />
                    <h2>Distribution</h2></a>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
                <div className={styles.bannerBottomsections}>
                <a href="#"><img src="assets/social-icons/scissors.png" alt="" />
                    <h2>kiaAndCo</h2></a>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                </div>
            </div> */}
        {/* Bottom sections end*/}
      </div>
    </>
  );
};

export default HeroBanner;
