import React from "react";
import styles from "./NetworkCapacity.module.css";
import ContactBtn from "../Buttons/Button";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";

const NetworkCapacity = () => {
  return (
    <>
      <div className={styles.OurValuesPage}>
        <div className={styles.OurValuesIntro}>
          <div className={styles.OurValuesHeading}>
            <h1>Network and Capacities</h1>
            <h2>
              7.5-acre facility | Cutting-edge technologies | 2000+ skilled
              workforce
            </h2>
            <p>
              At our state-of-the-art <span className={styles.span}>7.5-acre facility</span>, we combine creativity,
              innovation, and advanced technology to <span className={styles.span}>lead the industry in
              knitwear and outerwear</span>. Supported by a workforce of <span className={styles.span}>over 2000+
              skilled professionals</span>, we ensure seamless operations across the
              entire production chain, from material sourcing to rigorous
              testing. Our commitment to quality, efficiency, and employee
              well-being drives our success, helping us maintain the trust of
              our valued customers and stay at the forefront of the industry.
            </p>
          </div>
        </div>
      </div>

    <div className={styles.map}>
        <img src="assets/map.jpg" alt="" />
    </div>




    {/* Gallery Start */}
    <div className={styles.container} id="imagesSection">
            <div className={styles.imageSection} id={styles.galleryImg1}>
                <img src="assets/103.jpg" alt="" />
                <h2>2000+</h2>
                <p><span className={styles.span}>Total Workforce</span></p>
            </div>
            <div className={styles.imageSection} id={styles.galleryImg2} >
                <img src="assets/7.jpg" alt="" />
                  <h2>277 pieces/hour</h2>
                <p><span className={styles.span}>Sweaters </span></p>
            </div>
            <div className={styles.imageSection}  id={styles.galleryImg3}>
                <img src="assets/Jackets/92.jpg" alt="" />
                  <h2>57 jackets/hour</h2>
                <p><span className={styles.span}>Jackets </span></p>
            </div>
            <div className={styles.imageSection}  id={styles.galleryImg4}>
                <img src="assets/clothes-images/62.jpg" alt="" />
                  <h2>10,00,000</h2>
                <p><span className={styles.span}> T-Shirts & Sweatshirts</span></p>
            </div>
        
      
            <div className={styles.imageSection}  id={styles.galleryImg5}>
                <img src="assets/ShoeProduction/68.jpg" alt="" />
                  <h2>86 pairs/hour</h2>
                <p><span className={styles.span}>Shoe Uppers</span></p>
            </div>
            <div className={styles.imageSection}  id={styles.galleryImg6}>
                <img src="assets/76.jpg" alt="" />
                  <h2>4,00,000 Sq. Ft.</h2>
                <p><span className={styles.span}>Production Area</span></p>
            </div>
    </div>
        
    {/* Gallery End */}
    <Brands />
    <Footer />







    </>
  );
};

export default NetworkCapacity;
