import React from "react";
import styles from "./Awards.module.css";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";
import backgroundVideo from './video/celebration.mp4';
import Slider from "react-slick/lib/slider";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Awards = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "30px",
    slidesToShow: 3,
    speed: 1000,
    Dot: false,
  };
  return (
    <>
    <video autoPlay loop muted id={styles.video}>
      <source src={backgroundVideo} type="video/mp4" />
    </video>






    <div className={styles.sliderContainer}>
        <Slider {...settings}>
          <div>
          <img src="assets/Awards/no-bg2/1.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/2.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/3.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/4.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/5.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/6.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/7.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/8.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/9.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/10.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/11.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/12.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/13.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/14.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/15.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/16.png" alt="" />
          </div>
          <div>
          <img src="assets/Awards/no-bg2/17.png" alt="" />
          </div>
        </Slider>
      </div>

      <Brands />
      <Footer />
    </>
  );
};

export default Awards;
