import React from "react";
import styles from "./Footer.module.css";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const Footer = () => {
  return (
    <>
      <div className={styles.Footer}>
        <div className={styles.fst}>
          <img src="assets/logos/Active-final-1.png" alt="" />
          <p>
            At Active Clothing Co. Limited, we take immense pride in being your
            #1 solution for all textile needs. As leaders in comprehensive
            textile solutions in India, we specialize in top-tier apparel
            solutions, from conceptual design to final manufacturing. Our
            services encompass design, development, sourcing, manufacturing, and
            retail, creating an integrated platform that drives innovation and
            excellence in every product.
          </p>
        </div>
        <div className={styles.fst2}>
        <h2>Address</h2>
          <p>
          Plot no. E-225, Phase 8B, Ind. Area, Mohali
          </p>
          <div className={styles.fst2}>
        <h2>Social Media Links</h2>
            <div className={styles.socialIcons}>


           <a href="https://www.facebook.com/profile.php?id=61560272015567" target="_blank" rel="noopener noreferrer"><FacebookIcon /></a>
           <a href="https://www.instagram.com/activeclothing.in/" target="_blank" rel="noopener noreferrer"><InstagramIcon />
           </a>
           <a href="https://www.linkedin.com/company/active-clothing/" target="_blank" rel="noopener noreferrer"><LinkedInIcon />
           </a>
           <a href="https://x.com/ActiveClothingg" target="_blank" rel="noopener noreferrer"><TwitterIcon />
           </a>
            </div>
        </div>
        </div>
        <div className={styles.fst2}>
        <h2>Contact Us</h2>
          <p>
          Phone: 0172-4313300
          </p>
          <p>
          Fax: 0172-4313300
          </p>
          <p>
          Email: csco@activesourcing.org
          </p>
          
          <p> <a className={styles.shareMarket} href="https://www.bseindia.com/stock-share-price/active-clothing-co-ltd/active/541144/" target="_blank">
          <TrendingUpIcon /> Active Clothing Co Ltd 103.75 -8.95 -7.94% </a>
          </p>
        </div>
      </div>
      <div className={styles.copywrite}>
        <p>&copy; Copyright 2024 - Active Clothing Co. Limited </p>
      </div>
    </>
  );
};

export default Footer;
