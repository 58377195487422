import React from "react";
import styles from "./Header.module.css";
import { Link } from "react-router-dom";

const Header = () => {

  return (
    <>
      <div className={styles.header}>
        <div className={styles.logo}>
        <Link to="/"><img src="assets/logos/Active-final-1.png" alt="" /></Link>
        </div>

        <div className={styles.mainMenu}>
        <Link className={styles.active} to="/manufacturing">MANUFACTURING</Link>
        <Link to="/retailing">RETAILING</Link>
        <Link to="/distribution">DISTRIBUTION</Link>
        <Link to="/kiaandco">KIAandCO</Link>
        <Link className={styles.menuBtn} to="/sidebar">
         <img src="assets/social-icons/menu.png" alt="" />
        </Link>
        </div>
      </div>

    </>
  );
};

export default Header;
