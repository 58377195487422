import React from "react";
import styles from "./Kiaandco.module.css";
import ContactBtn from "../Buttons/Button";
import Footer from "../Footer/Footer";
import Brands from "../Brands/Brands";

const Kiaandco = () => {
  return (
    <>
      <div className={styles.manufacturingPage}>
        <div className={styles.MenufactureIntro}>
          <div className={styles.FlatKnitsHeading}>
            <h1>KiaAndCo</h1>
            <h2>
              Your Creative Partner for Knitwear and Woven Design
            </h2>
            <p>
              Welcome to <span>KIAANDCO PVT. LTD.</span>, a dynamic creative consultancy
              based in Mohali, Punjab, specializing in innovative knitwear and
              woven design solutions. <span>Founded in 2022 by Kalika Mehra</span>, we focus
              on blending creativity, sustainability, and modern technology to
              offer personalized design consultancy services. From programming
              and knitting to pattern making, garment customization, color trend
              analysis, and marketing tools, our expert team is dedicated to
              helping designers, brands, manufacturers, and wholesalers create
              cohesive collections and unique garment designs. Contact us today
              to explore how our services can enhance your business.
            </p>
            {/* <a href="#imagesSection">
              <ContactBtn label="Explore Our Collections" />
            </a> */}
          </div>
        </div>
      </div>
      <Brands />
      <Footer />
    </>
  );
};

export default Kiaandco;
