import Brands from '../../Brands/Brands';
import Footer from '../../Footer/Footer';
import styles from './Shoeuppers.module.css';


const ShoeUppers = () => {
  return (
    <>
     <div className={styles.mainContainer}>
        <div className={styles.containerBanner}>
          <img src="assets/BG/shoeBG.jpeg" alt="" />
          <div className={styles.gradientbg}></div>
        </div>

        <div className={styles.manufacturingPage}>
          <div className={styles.MenufactureIntro}>
            <div className={styles.FlatKnitsHeading}>
              <h1>Shoe Uppers</h1>
            <h2>India's First <span>STOLL-Driven</span> Shoe Uppers Manufacturer</h2>
            <p>
                Revolutionizing local production with cutting-edge technology,
                delivering <span>86 pairs per hour</span> for top global brands.
              </p>
            </div>
          </div>
        </div>
      </div>


      <div className={styles.JacketsContainer1}>
        <div className={styles.ImagesSection}>
        <h3>Using New Tech for Local Production</h3>
          <p>
            At Active Clothing Co. Limited, we’ve started using fancy machines
            from <span className={styles.span}> Germany</span>, called{" "}
            <span className={styles.span}>STOLL machines</span>, to make shoe
            uppers. This helps us offer more types of shoes and supports local
            manufacturing, meaning we rely less on imports.
          </p>

        </div>
        <div className={styles.JacketsImages}>
        <h3>Leading the Way</h3>
          <p>
            We’re the <span className={styles.span}>first ones in India</span>{" "}
            to use STOLL tech for making shoe uppers. Our shoes, making 86 pairs
            per hour, have caught the attention of big{" "}
            <span className={styles.span}>brands </span> like{" "}
            <span className={styles.span}> Reebok, Adidas,</span> and{" "}
            <span className={styles.span}>U.S. Polo</span>. It shows how much we care about making great
            shoes that are also innovative.
          </p>

        </div>
      {/* </div> */}
      {/* <div className={styles.JacketsContainer1}> */}
        <div className={styles.ImagesSection}>
        <h3>Quality and Quantity</h3>
          <p>
            With a production <span className={styles.span}>capacity of 500,000 pieces
            per year</span>, we are the largest manufacturer of outerwear jackets in
            Punjab. Our advanced facility houses <span className={styles.span}>over 350 machines across 8
            lines</span>, ensuring that each jacket is crafted with the same level of
            care and attention to detail.
          </p>

        </div>
      </div>
      <Brands />
      <Footer />
    </>
  );
};

export default ShoeUppers