import React from "react";
import styles from './DesignDelopment.module.css';
import Footer from "../Footer/Footer";
import Brands from "../Brands/Brands";

const DesignDevelopment = () => {
  return (
    <>
      <div className={styles.manufacturingPage}>
        <div className={styles.MenufactureIntro}>
          <div className={styles.FlatKnitsHeading}>
            <h1>Design and Development</h1>
            <h2>
              From Sketch to Store <br />
              Turning Ideas into Reality
            </h2>
            <p>
              At Active, our Design and Development Centre empowers clients to
              transform their creative visions into tangible garments. With
              state-of-the-art infrastructure and a dedicated team of experts,
              we ensure a seamless process from initial sketch to final product,
              maintaining the highest standards of quality and craftsmanship. By
              combining technical expertise with innovation, we not only meet
              but exceed market expectations, offering unique, high-volume
              designs that drive success for our clients.
            </p>
          </div>
        </div>

        <div className={styles.designingHeading}>
          <h3>Bringing Ideas to Life</h3>
          <p>
            At Active, we take pride in our customer-centric approach,
            empowering our clients to convert their sketches into tangible
            garments. Our design and development centre provides the necessary
            infrastructure and support, ensuring that design concepts are
            seamlessly translated from sketch to store with the highest
            standards of quality and craftsmanship.
          </p>
        </div>

        <div className={styles.valuesContainer} id="imagesSection">
          <div className={styles.imageSection}>
            <h3>Strategy</h3>
            <img src="assets/strategy.jpg" alt="" />
            <p>
              Our design and development centre empowers customers to convert
              their sketches into tangible garments, providing the necessary
              infrastructure and support.
            </p>
          </div>
          <div className={styles.imageSection}>
            <h3>Technical Expertise</h3>
            <img src="assets/expert.jpg" alt="" />
            <p>
              We embrace continuous learning, offering growth through training
              and industry resources. Curiosity and lifelong learning drive our
              personal and professional development.
            </p>
          </div>
          <div className={styles.imageSection}>
            <h3>Innovation and Creativity</h3>
            <img src="assets/Creativity.jpg" alt="" />
            <p>
              Integrity is our foundation. We adhere to the highest ethical
              standards, ensuring honesty, transparency, and accountability. We
              build trust by consistently delivering on our promises.
            </p>
          </div>
        </div>
        <div className={styles.valuesContainer} id="imagesSection">
          <div className={styles.imageSection}>
            <h3>Proven Track Record</h3>
            <img src="assets/Work-Done.jpg" alt="" />
            <p>
              Our design and development centre empowers customers to convert
              their sketches into tangible garments, providing the necessary
              infrastructure and support.
            </p>
          </div>
          <div className={styles.imageSection}>
            <h3>Collaborative Approach</h3>
            <img src="assets/Collaborative.jpg" alt="" />
            <p>
              We embrace continuous learning, offering growth through training
              and industry resources. Curiosity and lifelong learning drive our
              personal and professional development.
            </p>
          </div>
          <div className={styles.imageSection}>
            <h3>Research and Development</h3>
            <img src="assets/Research.jpg" alt="" />
            <p>
              Integrity is our foundation. We adhere to the highest ethical
              standards, ensuring honesty, transparency, and accountability. We
              build trust by consistently delivering on our promises.
            </p>
          </div>
        </div>

        <div className={styles.designingHeading}>
          <h3>
            WE MAKE IT POSSIBLE TO CONVERT A DESIGN INTO PRODUCT GIVING THE
            MARKET A RELIABLE MERCHANDISE TO SELL.
          </h3>
          <h4>
            This success of our product development centre is achieved through:
          </h4>
          <ul>
            <li>
              research and development of innovative yarn blends and
              state-of-the-art knitting technologies;
            </li>
            <li>
              integration of the latest technologies and trends with design;
            </li>
            <li>
              forecast of the latest trends in fashion-knit as a value-added
              service to customers;
            </li>
            <li>
              working closely with our customers to turn design concepts and
              sketches into best-value samples that meet market needs.
            </li>
          </ul>
        </div>
      </div>

      <Brands />
      <Footer />
    </>
  );
};

export default DesignDevelopment;
