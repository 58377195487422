import styles from './HomePage.module.css';
import ContactBtn from "../Buttons/Button";
import HeroBanner from "../HeroSection/HeroBanner";
import { Link } from "react-router-dom";

const HomePage = () => {
   
    return (
        <>
        <HeroBanner />
        <div className={styles.homeContainer}>
            <div className={styles.row}>
            <div className={styles.manufacturingCol}>
                <div className={styles.columns}>
                    
                    <h2>manufacturing</h2>
                    <p>Active Clothing's Manufacturing division focuses on crafting premium jackets, circular knits, and shoe uppers using advanced techniques and quality materials, ensuring durability and innovation for diverse market needs.</p>
                    <Link to="/manufacturing"><ContactBtn label="know More" /></Link>
                    </div>
                </div>
                <div className={styles.distributionCol}>
                <div className={styles.columns}>
                    <h2>distribution</h2>
                    <p>Active Clothing's Distribution streamlines the global supply of jackets, circular knits, and shoe uppers, ensuring timely delivery and maintaining product quality through a reliable and efficient logistics network.</p>
                    <Link to="/distribution"><ContactBtn label="know More" /></Link> 
                </div>
                </div>
            </div>
            <div className={styles.row}>
            <div className={styles.retailCol}>
                <div className={styles.columns}>
                    <h2>retail</h2>
                    <p>Active Clothing's Retail division offers a diverse range of premium jackets, circular knits, and shoe uppers, providing customers with high-quality products through a seamless shopping experience across multiple channels.</p>
                    <Link to="/retailing"><ContactBtn label="know More" /></Link> 
                </div>
                </div>
                <div className={styles.kiaandcoCol}>
                <div className={styles.columns}>
                    <h2>kiaandco</h2>
                    <p>Active Clothing's KiaandCo division focuses on delivering stylish, high-quality apparel and footwear, combining modern design with functionality to cater to the evolving fashion needs of discerning customers.</p>
                    <Link to="/kiaandco"><ContactBtn label="know More" /></Link>
                </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default HomePage;