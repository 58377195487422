import React, { useState } from "react";
import styles from "./Sidebar.module.css";
import { Link } from "react-router-dom";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';


const Sidebar = () => {
  return (
    <>
      <div className={styles.sidebarContainer}>
        <div className={styles.imageSection}>
          <Link to="/">
            <img
              className={styles.backLogo}
              src="assets/social-icons/left-arrow.png"
              alt=""
            />
          </Link>
          <img src="assets/6.png" alt="" />
        </div>

        <div className={styles.sidebarSection}>
          <div className={styles.logo}>
            <Link to="/">
              <img src="assets/logos/Active-final-1.png" alt="" />
            </Link>
          </div>
          <div className={styles.menuLinksSection}>
            <div className={styles.menuLinks}>
              <Link to="/about">About Us</Link>
              <Link to="/history">History</Link>
              <Link to="/our-people">Our People</Link>
              <Link to="/our-Values">Our Values</Link>
              <Link to="/networkCapacity">Network & Capacities</Link>
              <div className={styles.dropdown}>
                <Link to="/ourBusiness">Our Business</Link>
                {/* dropdown start */}
                <div className={styles.dropdownContent}>
                  <Link to="/designDevelopment">Design & Development</Link>
                  <Link
                    to="https://jenixcloud.com/Zoommet/jsp/login/login.jsp"
                    target="_blank"
                  >
                    {" "}
                    Digital Library
                  </Link>
                  {/* dropdown end */}
                </div>
              </div>
              <Link to="/certifications">Certifications</Link>
              <Link to="/awards">Awards</Link>
            </div>

            <div className={styles.menuLinks}>
              <Link
                to="https://activeclothing.in/wp-content/uploads/2024/09/company-profile.pdf"
                target="_blank"
              >
                Company Profile
              </Link>
              <div className={styles.dropdown}>
                <Link to="/manufacturing">Manufacturing</Link>
                {/* dropdown start */}
                <div className={styles.dropdownContent}>
                  <Link to="/flatKnits">Flat Knits</Link>
                  <Link to="/circularKnits">Circular Knits</Link>
                  <Link to="/Jackets">Jackets</Link>
                  <Link to="/ShoeUppers">Shoe Uppers</Link>
                  <Link to="/toys">Toys</Link>
                  {/* dropdown end */}
                </div>
              </div>
              <Link to="/retailing">Retail</Link>
              <Link to="/distribution">Distribution</Link>
              <Link to="/kiaandco">KiaAndCo</Link>
            </div>
            <div className={styles.menuLinks}>
              <div className={styles.dropdown}>
                <Link to="/investors">Investors</Link>
                {/* dropdown start */}
                <div className={styles.dropdownContent}>
                  <Link to="/financials">Financials</Link>
                </div>
              </div>
              {/* dropdown end */}
              <Link to="/EventNews">Events & News</Link>
              <Link to="/contactUs">Contact Us</Link>
            </div>
          </div>

          <div className={styles.social_icons}>
          <a href="https://www.facebook.com/profile.php?id=61560272015567" target="_blank" rel="noopener noreferrer"><FacebookIcon /></a>
           <a href="https://www.instagram.com/activeclothing.in/" target="_blank" rel="noopener noreferrer"><InstagramIcon />
           </a>
           <a href="https://www.linkedin.com/company/active-clothing/" target="_blank" rel="noopener noreferrer"><LinkedInIcon />
           </a>
           <a href="https://x.com/ActiveClothingg" target="_blank" rel="noopener noreferrer"><TwitterIcon />
           </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
