import React from "react";
import styles from "./CircularKnits.module.css";
import ContactBtn from "../../Buttons/Button";
import Footer from "../../Footer/Footer";
import Brands from "../../Brands/Brands";

const CircularKnits = () => {
  return (
    <>
     <div className={styles.mainContainer}>
        <div className={styles.containerBanner}>
          <img src="assets/BG/circularBg.jpeg" alt="" />
          <div className={styles.gradientbg}></div>
        </div>

        <div className={styles.manufacturingPage}>
          <div className={styles.MenufactureIntro}>
            <div className={styles.FlatKnitsHeading}>
              <h1>Circular Knits</h1>
            <h2>India's Premier Circular Knits Manufacturer –</h2>
            <p>
          At Active Clothing Co. Limited, our Circular Knits Division operates
          like a close-knit family, produces approximately{" "}
          <span>114 garments per hour</span>. With four specialized production
          lines, we offer a versatile collection featuring solid and
          auto-striper Polos, sweatshirts, and joggers. These pieces are crafted
          from premium fabrics, including cotton, cotton-polyester blends,
          indigo, and fleece, ensuring both comfort and style.
        </p>
              <a href="#imagesSection">
                <ContactBtn label="Explore Our Collections" />
              </a>
            </div>
          </div>
        </div>
      </div>


      <div className={styles.CircularKnitsContainer1}>
        <div className={styles.ImagesSection}>
          <h3>Careful Fabric Selection</h3>
          <p>
            To ensure our clothes are top-notch, we pick our fabrics from
            trusted suppliers. Our skilled team uses over 100 machines with
            precision, ensuring every garment is made with attention to detail
            and care.
          </p>
        </div>
        <div className={styles.CircularKnitsImages}>
          <h3> Sustainability Matters</h3>
          <p>
            Our T-shirts, made from BCI cotton, are more than just clothes;
            they’re a statement of our commitment to sustainability. By choosing
            BCI cotton, we support sustainable cotton farming practices,
            benefiting the environment and local communities.
          </p>
        </div>
      </div>
      

      {/* Gallery Start */}
      <div className={styles.container} id="imagesSection">
        <div className={styles.imageSection}>
          <img
            src="https://activeclothing.in/wp-content/uploads/2024/07/2-1.jpg"
            alt=""
          />
        </div>
        <div className={styles.imageSection}>
          <img
            src="https://activeclothing.in/wp-content/uploads/2024/07/3-1.jpg"
            alt=""
          />
        </div>
        <div className={styles.imageSection}>
          <img
            src="https://activeclothing.in/wp-content/uploads/2024/07/4-1.jpg"
            alt=""
          />
        </div>
        <div className={styles.imageSection}>
          <img
            src="https://activeclothing.in/wp-content/uploads/2024/07/5-2.jpg"
            alt=""
          />
        </div>
        <div className={styles.imageSection}>
          <img
            src="https://activeclothing.in/wp-content/uploads/2024/07/6-2.jpg"
            alt=""
          />
        </div>
        <div className={styles.imageSection}>
          <img
            src="https://activeclothing.in/wp-content/uploads/2024/07/1-1.jpg"
            alt=""
          />
        </div>
      </div>
      {/* Gallery End */}
      <Brands />
      <Footer />
    </>
  );
};

export default CircularKnits;
