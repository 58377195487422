import React from "react";
import styles from './OurHistory.module.css';
import ContactBtn from "../Buttons/Button";
import Footer from "../Footer/Footer";
import Brands from "../Brands/Brands";

const OurHistory = () => {
  return (
    <>
      <div className={styles.manufacturingPage}>
        <div className={styles.MenufactureIntro}>
          <div className={styles.FlatKnitsHeading}>
            <h1>
             History
            </h1>
            <p>
              Active Clothing has built a strong reputation in the apparel
              distribution industry, starting with the distribution of
              <span> Levi’s</span> and growing to become the{" "}
              <span>
                {" "}
                largest Levi’s distributor in the upper north of India
              </span>
              . Operating in regions like Chandigarh, Punjab, Jammu & Kashmir,
              and Himachal Pradesh, the company now distributes top brands such
              as Arrow, Flying Machine, Rare Rabbit, and more.{" "}
              <span>Serving over 200 retailers in 50 towns </span>, Active
              Clothing continues to expand its reach and has received multiple
              awards for its achievements in the industry.
            </p>
            {/* <a href="#imagesSection">
              <ContactBtn label="Explore Our Collections" />
            </a> */}
          </div>
        </div>
      </div>

<div className={`${styles.Video_Container}`}>
      <video autoPlay muted controls loop id={styles.video}>
         <source src="assets/Videos/HistoryActive.mp4" type="video/mp4" />
        </video>
        </div>
    <Brands />
      <Footer />
    </>
  );
};

export default OurHistory;
