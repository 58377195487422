import React from "react";
import styles from "./Certifications.module.css";
import ContactBtn from "../Buttons/Button";
import Footer from "../Footer/Footer";
import Brands from "../Brands/Brands";

const Certifications = () => {
  return (
    <>
      <div className={styles.certificationsPage}>
        <div className={styles.certificationsIntro}>
          <div className={styles.certificationsHeading}>
            <h1>Certifications</h1>
            <p>
              At Active Clothing Co. Limited, we’re dedicated to sustainable and
              ethical practices, ensuring that our products meet the highest
              standards of quality and responsibility. Here are the
              certifications that reflect our commitment:
            </p>
          </div>
        </div>
      </div>

      {/* Sections Start */}
      <div className={styles.sectionsContainer}>
        <div className={styles.sectionImages} id={styles.sectionImages1}>
          <img src="assets/certificate-image/certificate1.png" alt="" />
          <h4>Global Organic Textile Standard (GOTS): </h4>
          <p>
            This certification guarantees that our products are made from
            organic fibres and that our production processes meet stringent
            environmental and social criteria.
          </p>
        </div>
        <div className={styles.sectionImages} id={styles.sectionImages2}>
          <img src="assets/certificate-image/certificate2.png" alt="" />
          <h4>Organic Content Standard (OCS): </h4>
          <p>
            This certification verifies the organic content of our products,
            ensuring that they contain a specified amount of organic material.
          </p>
        </div>
        <div className={styles.sectionImages} id={styles.sectionImages3}>
          <img src="assets/certificate-image/certificate3.png" alt="" />
          <h4>Responsible Wool Standard (RWS): </h4>
          <p>
            This certification ensures that the wool used in our products comes
            from farms that adhere to high standards of animal welfare and
            environmental responsibility.
          </p>
        </div>
        <div className={styles.sectionImages} id={styles.sectionImages4}>
          <img src="assets/certificate-image/certificate4.png" alt="" />
          <h4>Global Recycled Standard (GRS): </h4>
          <p>
            This certification confirms that our products contain recycled
            materials, supporting the circular economy and reducing waste.
          </p>
        </div>
        <div className={styles.sectionImages} id={styles.sectionImages1}>
          <img src="assets/certificate-image/certificate5.png" alt="" />
          <h4>Better Cotton Initiative (BCI) Certification: </h4>
          <p>
            This certification confirms that the cotton yarn used in our
            products is sourced from BCI-certified farms, promoting sustainable
            cotton production practices.
          </p>
        </div>
        <div className={styles.sectionImages} id={styles.sectionImages1}>
          <img src="assets/certificate-image/certificate6.png" alt="" />
          <h4> WRAP (Worldwide Responsible Accredited Production): </h4>
          <p>
            This certification demonstrates our commitment to ethical
            manufacturing practices, including fair wages, safe working
            conditions, and compliance with labor laws.
          </p>
        </div>
        <div className={styles.sectionImages} id={styles.sectionImages1}>
          <img src="assets/certificate-image/certificate7.png" alt="" />
          <h4>Social & Labor Convergence Program (SLCP): </h4>
          <p>
            This certification reflects our participation in a global initiative
            to improve labor conditions in the garment industry through
            collaboration and shared standards.
          </p>
        </div>
        <div className={styles.sectionImages} id={styles.sectionImages1}>
          <img src="assets/certificate-image/certificate8.png" alt="" />
          <h4> HIGG INDEX:</h4>
          <p>
            This tool helps us measure and assess the environmental and social
            impacts of our products and operations, allowing us to make informed
            decisions to reduce our footprint.
          </p>
        </div>
        <div className={styles.sectionImages} id={styles.sectionImages1}>
          <img src="assets/certificate-image/certificate9.png" alt="" />
          <h4>KONTOOR: </h4>
          <p>
            This certification signifies our commitment to sustainable practices
            and responsible sourcing in the textile industry.
          </p>
        </div>

        <div className={styles.sectionImages} id={styles.sectionImages1}>
          <img src="assets/certificate-image/certificate10.png " alt="" />
          <h4>SEDEX (Supplier Ethical Data Exchange): </h4>
          <p>
            This membership demonstrates our commitment to ethical sourcing and
            transparency in our supply chain.
          </p>
        </div>
        <div className={styles.sectionImages} id={styles.sectionImages2}>
          <img src="assets/certificate-image/certificate11.png" alt="" />
          <h4>CLEANCHAIN:</h4>
          <p>
           This certification signifies our commitment to reducing
            environmental impacts in our supply chain and promoting
            sustainability in the textile industry.
          </p>
        </div>
        
        
       
        
       
        
        
      </div>
      <Brands />
      {/* Sections End */}
      <Footer />
    </>
  );
};

export default Certifications;
