import React from 'react';
import styles from './Financials.module.css';
import Brands from '../Brands/Brands';
import Footer from '../Footer/Footer';

const Financials = () => {
  return (
    <>
     <div className={styles.mainContainer}>
        <div className={styles.containerBanner}>
          <img src="assets/trust.jpg" alt="" />
          <div className={styles.gradientbg}></div>
        </div>

        <div className={styles.manufacturingPage}>
          <div className={styles.MenufactureIntro}>
            <div className={styles.FlatKnitsHeading}>
            <h1>
            Financials
            </h1>
            </div>
          </div>
        </div>
      </div>


    <div className={styles.Container}>
        <div className={styles.Boxes}>
            <h2>Notices</h2>
            <ul>
                <li> <a href="">Notice of 22nd Annual General Meeting of the Members of Active Clothing Co Limited</a></li>
                <li> <a href="">Intimation of Board Meeting schedule to be held on August 24, 2024</a></li>
                <li> <a href="">Intimation of Board Meeting schedule to be held on August 14, 2024</a></li>
                <li> <a href="">Intimation of Board Meeting schedule to be held on 30th May, 2024</a></li>
                <li> <a href="">Intimation of Board Meeting schedule to be held on 30th May, 2023</a></li>
                <li> <a href="">Intimation of Board Meeting schedule to be held on 14th November, 2023</a></li>
                <li> <a href="">Proceedings of the 21st Annual General Meeting of the Members</a></li>
                <li><a href="">Voting Results of 21st Annual General Meeting of Active Clothing Co Limited</a></li>
                <li><a href="">Intimation to change in Auditor of Company</a></li>
                <li><a href="">Notice of 21st Annual General Meeting</a></li>
                <li> <a href="">Intimation of Board Meeting schedule to be held on 26th August, 2023</a></li>
                <li><a href="">Intimation of Board Meeting schedule to be held on 14th August, 2023</a></li>
                <li><a href="">Notice of Board meeting to be held on 31st January, 2023.</a></li>
                <li><a href="">Notice of Board Meeting to Be held on 12th November, 2022</a></li>
                <li><a href="">20th Annual General Meeting (AGM) Voting Results</a></li>
                <li><a href="">Outcome of Board Meeting</a></li>
                <li> <a href="">Notice for 20th Annual General Meeting of the Members of Active Clothing Company Limited</a></li>
                <li>  <a href="">Intimation of Board Meeting</a></li>
            </ul>
        </div>
        <div className={styles.Boxes}>
            <h2>Financial Results</h2>
            <ul>
                <li><a href="">Unaudited Financial Results for the quarter ending June 30, 2024</a></li>
                <li><a href="">Unaudited Financial Results for the quarter ending Dec 31, 2023</a></li>
                <li><a href="">Unaudited Financial Results for the quarter ending Sep 30, 2023</a></li>
                <li><a href="">Unaudited Financial Results for the quarter ending June 30, 2023</a></li>
                <li><a href="">Audited Financial Results for the Quarter ended and year ended 31.03.2023</a></li>
                <li><a href="">UnAudited Financial Results for the Quarter/Nine Months ended 31st Dec, 2022</a></li>
                <li><a href="">UnAudited Financial Results for the Quarter and the half year ended 30th Sep, 2022</a></li>
                <li><a href="">Audited Financial Results for the year ended 31st March, 2022</a></li>
                <li><a href="">Unaudited Financial Results for the Half year ended 30th September, 2021</a></li>
                <li><a href="">Unaudited Financial Year ended Sep 30, 2020</a></li>
                <li><a href="">Audited Financial Year ended March31, 2020</a></li>
                <li><a href="">Unaudited Financial Year ended Sep 30, 2019</a></li>
                <li><a href="">Audited Financial Year ended March31, 2019</a></li>
                <li><a href="">Unaudited financial results for the half year ended on September 30, 2018.</a></li>
                <li><a href="">Audited Financial Results for the Half Year and Year Ended March 31, 2018</a></li>
            </ul>
        </div>
        <div className={styles.Boxes}>
            <h2>Shareholding Pattern</h2>
            <ul>
                <li><a href="">Shareholding Pattern June 30, 2024</a></li>
                <li><a href="">Shareholding Pattern March 31, 2024</a></li>
                <li><a href="">Shareholding Pattern Dec 31, 2023</a></li>
                <li><a href="">Shareholding Pattern Sep 30, 2023</a></li>
                <li><a href="">Shareholding Pattern March 30, 2023</a></li>
                <li><a href="">Shareholding Pattern Jun 30, 2023</a></li>
                <li><a href="">Shareholding Pattern Sep 30, 2022</a></li>
                <li><a href="">Shareholding Pattern Mar 31, 2022</a></li>
                <li><a href="">Shareholding Pattern Sep 30, 2021</a></li>
                <li><a href="">Shareholding Pattern March 31, 2021</a></li>
                <li><a href="">Shareholding Pattern Sep 30, 2020</a></li>
                <li><a href="">Shareholding Pattern March 31, 2020</a></li>
                <li><a href="">Shareholding Pattern Sep 30, 2019</a></li>
                <li><a href="">Shareholding Pattern March 31, 2019</a></li>
            </ul>
        </div>
        <div className={styles.Boxes}>
            <h2>Regulation Compliance Report</h2>
            <ul>
                <li><a href="">Regulation 27 June 30, 2024</a></li>
                <li><a href="">Regulation 13 June 30, 2024</a></li>
                <li><a href="">Regulation 24 A March 31, 2024</a></li>
                <li><a href="">Regulation 24 A March 31, 2023</a></li>
                <li><a href="">Regulation 13</a></li>
                <li><a href="">Regulation_27(2) Compliance report 2023</a></li>
                <li><a href="">Regulation_27(2) Compliance report 2023</a></li>
                <li><a href="">Regulation 74(5) Compliance report 2023</a></li>
                <li><a href="">Regulation 27 Compliance report 2023</a></li>
                <li><a href="">Regulation 24A Compliance report 2022</a></li>
                <li><a href="">Regulation 24A Compliance report</a></li>
            </ul>
        </div>
        <div className={styles.Boxes}>
            <h2>Closure of Trading Window</h2>
            <ul>
                <li><a href="">Closure of Trading Window June 24</a></li>
                <li><a href="">Closure of Trading Window Mar 24</a></li>
                <li><a href="">Closure of Trading Window Dec 23</a></li>
                <li><a href="">Closure of Trading Window Sep 23</a></li>
                <li><a href="">Closure of Trading Window June 23</a></li>
                <li><a href="">Closure of Trading Window April 23</a></li>
                <li><a href="">Closure of Trading Window January 23</a></li>
            </ul>
        </div>
        <div className={styles.Boxes}>
            <h2>Annual Reports</h2>
            <ul>
                <li><a href="">22nd Annual Report 2023-24</a></li>
                <li><a href="">21st Annual Report 2022-23</a></li>
                <li><a href="">20th Annual Report 2021-22</a></li>
                <li><a href="">19th Annual Report</a></li>
                <li><a href="">18th Annual Report</a></li>
                <li><a href="">17th Annual Report</a></li>
                <li><a href="">16th Annual Report</a></li>
            </ul>
        </div>

        <div className={styles.Boxes}>
            <h2>Financial Statements</h2>
            <ul>
                <li><a href="">Financial Statement 2020-21</a></li>
                <li><a href="">Financial Statement 2019-20</a></li>
                <li><a href="">Financial Statement 2018-19</a></li>
                <li><a href="">Financial Statement 2017-18</a></li>
            </ul>
        </div>
        <div className={styles.Boxes}>
            <h2>Newspaper Publication</h2>
            <ul>
                <li><a href="">Newspaper Publications December 31, 2023</a></li>
                <li><a href="">Newspaper Publications March 31, 2023</a></li>
                <li><a href="">Newspaper Publications</a></li>
                <li><a href="">Regulation 30</a></li>
                <li><a href="">Non applicability of Regulation 32</a></li>
            </ul>
        </div>
      
        <div className={styles.Boxes}>
            <h2>Annual Returns</h2>
            <ul>
                <li><a href="">Annual Return 2022-23</a></li>
                <li><a href="">Annual Return 2021-22</a></li>
                <li><a href="">Annual Return 2020-21</a></li>
            </ul>
        </div>
        
       
      
        
        
        <div className={styles.Boxes}>
            <h2>Related Party Transactions</h2>
            <ul>
                <li><a href="">Related Party Transactions as on March 31, 2024</a></li>
            </ul>
        </div>
    </div>

  

    <Brands />
    <Footer />
    </>
  )
}

export default Financials