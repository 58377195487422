import React from "react";
import styles from "./Retail.module.css";
import Footer from "../Footer/Footer";
import Brands from "../Brands/Brands";
const Retailing = () => {
  return (
    <>
      <div className={styles.manufacturingPage}>
        <div className={styles.MenufactureIntro}>
          <div className={styles.FlatKnitsHeading}>
            <h1>AAGAIN</h1>
            <h2>
              Redefining Retail with Leading Fashion Brands for Every Style
            </h2>
            <p>
              In 2003, we launched <span>AAGAIN</span>, a dynamic retail concept
              where fashion meets personality. Step into any of our seven Punjab
              locations to discover a curated selection of top brands like
              <span> Levi’s</span>,<span> Flying Machine</span>, 
              <span> NIKE</span>, and more. AAGAIN isn’t just a store; it’s a
              fashion journey designed for enthusiasts like you. Our friendly
              staff is here to help you explore and elevate your style. Join us
              at AAGAIN and experience a world of fashion that celebrates your
              individuality.
            </p>
          </div>
        </div>
       
      </div>

    

      <div className={styles.banner}>
        <div className={styles.slider} style={{'--quantity': 10}}>
            <div className={styles.item} style={{'--position': 1}}><img src="assets/Gallery-images/image-11.jpg" alt="" /></div>
            <div className={styles.item} style={{'--position': 2}}><img src="assets/Gallery-images/image-1.jpg" alt="" /></div>
            <div className={styles.item} style={{'--position': 3}}><img src="assets/Gallery-images/image-2.jpg" alt="" /></div>
            <div className={styles.item} style={{'--position': 4}}><img src="assets/Gallery-images/image-3.jpg" alt="" /></div>
            <div className={styles.item} style={{'--position': 5}}><img src="assets/Gallery-images/image-4.jpg" alt="" /></div>
            <div className={styles.item} style={{'--position': 6}}><img src="assets/Gallery-images/image-5.jpg" alt="" /></div>
            <div className={styles.item} style={{'--position': 7}}><img src="assets/Gallery-images/image-6.jpg" alt="" /></div>
            <div className={styles.item} style={{'--position': 8}}><img src="assets/Gallery-images/image-7.jpg" alt="" /></div>
            <div className={styles.item} style={{'--position': 9}}><img src="assets/Gallery-images/image-8.jpg" alt="" /></div>
            <div className={styles.item} style={{'--position': 10}}><img src="assets/Gallery-images/image-9.jpg" alt="" /></div>
            </div>

          
        </div>
   













      <Brands />
      <Footer />
    </>
  );
};

export default Retailing;
