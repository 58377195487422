import React, { useState } from "react";
import styles from './Button.module.css';

const ContactBtn = (props) => {
    return (
        <>
          <button className={styles.ctaBtn}>{props.label}</button>
        </>
    );
};


// const ContactBtn = ({ isOutline, icon, text, ...rest }) => {

//   return (
//  <button 
//  {...rest}
//  className={isOutline ? styles.outline_btn : styles.primary_btn}>
//    {icon}
//    {text}
//  </button>
// )
// };


export default ContactBtn;