import React from "react";
import styles from "./EventNews.module.css";
import ContactBtn from "../Buttons/Button";
import Brands from "../Brands/Brands";
import Footer from "../Footer/Footer";

const EventNews = () => {
  return (
    <>
      <div className={styles.manufacturingPage}>
        <div className={styles.MenufactureIntro}>
          <div className={styles.FlatKnitsHeading}>
            <h1>Latest Events & News</h1>
            <p>
              Stay updated with the latest happenings at Active Clothing! From
              new product launches and industry collaborations to community
              initiatives and trade shows, this is your go-to source for all the
              exciting news and events. Explore how we are shaping the future of
              fashion with our innovative designs, sustainable practices, and
              customer-centric approach.
            </p>
          </div>
        </div>
        <hr />
      </div>
      {/*  */}
      <div className={styles.valuesContainer} id="imagesSection">
        <div className={styles.imageSection}>
          <h3>
            <span className={styles.span}>24 Aug</span> Board Meeting
          </h3>
          <img src="assets/leadership.jpg" alt="" />
          <p>
            We lead by example, setting high standards and inspiring positive
            change. Our leaders mentor and develop future leaders, fostering a
            culture of strong, visionary leadership.
          </p>
          <button className={`${styles.btns_color}`}>Know More </button>
        </div>
        <div className={styles.imageSection}>
          <h3>
            <span className={styles.span}>14 Aug</span> Board Meeting
          </h3>
          <img src="assets/learning.jpg" alt="" />
          <p>
            We embrace continuous learning, offering growth through training and
            industry resources. Curiosity and lifelong learning drive our
            personal and professional development.
          </p>
          <button className={`${styles.btns_color}`}>Know More </button>
        </div>
      </div>
      {/*  */}

      <Brands />
      <Footer />
    </>
  );
};

export default EventNews;
