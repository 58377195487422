import React from "react";
import styles from "./OurPeople.module.css";
import Footer from "../Footer/Footer";
import ContactBtn from "../Buttons/Button";
import Brands from "../Brands/Brands";

const OurPeople = () => {
  return (
    <>
      <div className={styles.OurPeoplePage}>
        <div className={styles.OurPeopleIntro}>
          <div className={styles.OurPeopleHeading}>
            <h1>
            Our People</h1>
            <h2>
              The Heart of Active Clothing
            </h2>
            <p>
              At Active Clothing, our strength lies in the passionate and
              talented individuals who drive our success. From skilled artisans
              and designers to innovative leaders and dedicated teams, every
              person plays a crucial role in crafting high-quality products that
              inspire. Together, we create a culture of collaboration,
              creativity, and commitment to excellence, ensuring we deliver the
              very best to our customers and partners.
            </p>
            <a href="#imagesSection">
              <ContactBtn label="Meet Our People" />
            </a>
          </div>
        </div>
      </div>

      {/* Gallery Start */}
      <div className={styles.container} id="imagesSection">
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/rajesh kumar Mehra.jpg" alt="" />
          <p>Rajesh Kumar Mehra <br /><span className={styles.span}>Managing Director</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Kalika Mehra.jpg" alt="" />
          <p>Kalika Mehra <br /><span className={styles.span}>Director</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Hitesh Manuja.jpg" alt="" />
          <p>Hitesh Manuja <br /><span className={styles.span}>CEO </span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/AjayKumarGupta.jpg" alt="" />
          <p>Ajay Gupta
        <br /><span className={styles.span}>Head Operations</span></p>
        </div>

        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Kanchan.jpg" alt="" />
          <p>Kanchan
         <br /><span className={styles.span}> Design Head</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Pardeep Raturi.jpg" alt="" />
          <p>Pradeep
         <br /><span className={styles.span}> Creative Head</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Mandeep Singh.jpg" alt="" />
          <p>Mandeep Singh
          <br /><span className={styles.span}>CFO</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Rajiv Khanna.jpg" alt="" />
          <p>Rajiv Khanna
          <br /><span className={styles.span}>Head HR & Admin</span></p>
        </div>

        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Vijaylaxmi.jpg" alt="" />
          <p>Vijay Lakshmi<br /><span className={styles.span}>DMM</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Hans Nautiyal.jpg" alt="" />
          <p>Hans Nautiyal
         <br /><span className={styles.span}> GM Productions</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Shivam Khurana.jpg" alt="" />
          <p>Shivam Khurana<br /><span className={styles.span}>Operations Head</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Vikram Chawra.jpg" alt="" />
          <p>Shivam Khurana<br /><span className={styles.span}>PPC</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/MANISH AGNIHOTRI.jpg" alt="" />
          <p>Shivam Khurana<br /><span className={styles.span}>DMM</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/RAM AGGARWAL.jpg" alt="" />
          <p>Shivam Khurana<br /><span className={styles.span}>IT Manager</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Vivek Gupta.jpg" alt="" />
          <p>Vivek<br /><span className={styles.span}>DMM</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Varinder Kumar.jpg" alt="" />
          <p>Varinder
         <br /><span className={styles.span}> Operations Head</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Chander Mohan.jpg" alt="" />
          <p>Chandan Mohan
          <br /><span className={styles.span}>DMM</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Vishal Sekhri.jpg" alt="" />
          <p>Vishal Sekhri<br /><span className={styles.span}>Vice President – Knits</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Uday Kotal.jpg" alt="" />
          <p>Uday <br /><span className={styles.span}> QA Manager</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Laxman Singh Rathod.jpg" alt="" />
           <p>Laxman<br /><span className={styles.span}>Logistics & Forwarding</span></p>
        </div>
        <div className={styles.imageSection}>
          <img src="assets/Active-Stafff-Images/Reetu Chopra Mittoo.jpg" alt="" />
          <p>Reetu Chopra
         <br /><span className={styles.span}> Admin Manager</span></p>
        </div>
      </div>

      {/* Gallery End */}
      <Brands />

      <Footer />
    </>
  );
};

export default OurPeople;
