import React from "react";
import styles from "./Business.module.css";
import ContactBtn from "../Buttons/Button";
import Footer from "../Footer/Footer";
import Brands from "../Brands/Brands";

const OurBusiness = () => {
  return (
    <>
      <div className={styles.ourBusinessPage}>
        <div className={styles.ourBusinessIntro}>
          <div className={styles.ourBusinessHeading}>
            <h1>Our Business</h1>
            <h2>
            ACTIVE: Pioneering Apparel Solutions with Innovation & Quality
            </h2>
            <p>
            At ACTIVE, we are more than just a garment distributor – we are <span className={styles.span}> leaders
        in the apparel industry</span> with a reputation for delivering excellence. Our
        journey from a modest capital base to a thriving network serving <span className={styles.span}>200+
        retailers across Punjab, Himachal Pradesh, J&K, and Chandigarh</span> showcases
        our commitment to growth and innovation. By integrating retail and
        manufacturing, we’ve strengthened our foothold in <span className={styles.span}>distribution,
        wholesale, and production</span>. Partnering with <span className={styles.span}>top brands in India</span>, we
        ensure that every product meets the highest standards of quality. Our
        own brand, <span className={styles.span}> ‘AAGAIN’</span>, embodies our dedication to style, craftsmanship,
        and customer satisfaction.
            </p>
            {/* <a href="#imagesSection">
              <ContactBtn label="Explore Our Collections" />
            </a> */}
          </div>
        </div>
        <div className={styles.businessImg}>
            <img src="assets/social-icons/Our-Business-graph.jpg" alt="" />
        </div>
      </div>

    <Brands />
      <Footer />
    </>
  );
};

export default OurBusiness;
